import React from 'react';
import PropTypes from 'prop-types';
import './FanExpoHQFooterTitle.scss';

/**
 * WHITE LABEL
 */
export default function FanExpoHQFooterTitle({ title }) {
  return <div className="c-fan-expo-hq-footer-title">{title}</div>;
}

FanExpoHQFooterTitle.propTypes = {
  title: PropTypes.string,
};
