import React from 'react';
import PropTypes from 'prop-types';
import FanExpoHQFooterTitle from '../FanExpoHQFooterTitle/FanExpoHQFooterTitle';
import LinkTo from '../../../../../shared/LinkTo/LinkTo';
import './FanExpoHQFooterSocial.scss';
import SocialIcon from '../../../../../shared/SocialIcon';

const socialLinksInfo = {
  facebook: {
    className: 'fab fa-facebook-f',
    labelName: 'Facebook',
  },
  instagram: {
    className: 'fab fa-instagram',
    labelName: 'Instagram',
  },
  youtube: {
    className: 'fab fa-youtube',
    labelName: 'YouTube',
  },
  twitter: {
    className: 'fab fa-x-twitter',
    labelName: 'Twitter',
  },
  threads: {
    className: 'fab fa-threads',
    labelName: 'Threads',
  },
  linkedin: {
    className: 'fab fa-linkedin-in',
    labelName: 'LinkedIn',
  },
  blog: {
    className: 'fa fa-rss',
    labelName: 'Blog',
  },
  artsy: {
    className: 'artsy-custom-icon',
    labelName: 'Artsy',
  },
  flickr: {
    className: 'fab fa-flickr',
    labelName: 'Flickr',
  },
  tiktok: {
    className: 'fab fa-tiktok',
    labelName: 'TikTok',
  },
  streamly: {
    className: 'fa-solid fa-circle-play',
    labelName: 'Streamly',
  },
  whatsapp: {
    className: 'fab fa-square-whatsapp',
    labelName: 'WhatsApp',
  },
};

/**
 * WHITE LABEL
 */
export default function FanExpoHQFooterSocial({ socials = [] }) {
  return socials.length > 0 ? (
    <div className="c-fan-expo-hq-footer-social">
      <FanExpoHQFooterTitle title="Stay connected" />
      <ul className="c-fan-expo-hq-footer-social__list">
        {socials.map((social) => {
          return (
            <li
              key={social.type}
              className="c-fan-expo-hq-footer-social__list-item"
            >
              <LinkTo
                to={social.url}
                target="_blank"
                className="c-fan-expo-hq-footer-social__link"
              >
                {socialLinksInfo[social.type]?.labelName}
              </LinkTo>
              <SocialIcon
                type={social.type}
                className="c-fan-expo-hq-footer-social__icon"
              />
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
}

FanExpoHQFooterSocial.propTypes = {
  socials: PropTypes.array,
};
