import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FanExpoHQFooterPages from './FanExpoHQFooterPages/FanExpoHQFooterPages';
import FanExpoHQFooterSocial from './FanExpoHQFooterSocial/FanExpoHQFooterSocial';
import FanExpoHQFooterCalendar from './FanExpoHQFooterCalendar/FanExpoHQFooterCalendar';
import ImageTag from '../../../../shared/ImageTag/ImageTag';
import LinkTo from '../../../../shared/LinkTo/LinkTo';
import './FanExpoHQFooter.scss';

/**
 * WHITE LABEL
 */
export function FanExpoHQFooter(props) {
  const {
    siteFooter: {
      data: { whiteLogo, footerLinks = [], footerTitle = '', social = [] } = {},
    } = {},
    siteHeader: { data: { title = '' } = {} } = {},
    options: {
      data: {
        brand: {
          whiteLogo: { path: whiteLogoPath } = {},
          logo: { path: logoPath } = {},
        } = {},
      } = {},
    } = {},
    pageConfig: { sitePath },
    bgColor,
  } = props;

  const logoSrc = whiteLogo ? whiteLogoPath : logoPath;
  const fanExpoHQLogo = logoSrc ? (
    <LinkTo
      to={'/' + sitePath + '/'}
      className="c-fan-expo-hq-footer__logo-link"
    >
      <ImageTag
        src={logoSrc}
        alt={title}
        lazy={false}
        imgixParams="h=80"
        className="c-fan-expo-hq-footer__logo"
      />
    </LinkTo>
  ) : null;

  return (
    <div className="c-fan-expo-hq-footer" style={{ backgroundColor: bgColor }}>
      <div className="container">
        {fanExpoHQLogo && (
          <div className="row c-fan-expo-hq-footer__logo-mobile-wrapper">
            <div className="col-xs-12">{fanExpoHQLogo}</div>
          </div>
        )}
        <div className="row">
          <div className="col-xs-6">
            <div className="c-fan-expo-hq-footer__pages-wrapper">
              {fanExpoHQLogo && (
                <div className="c-fan-expo-hq-footer__logo-wrapper">
                  {fanExpoHQLogo}
                </div>
              )}
              {footerLinks.length > 0 && (
                <FanExpoHQFooterPages
                  footerLinks={footerLinks}
                  footerTitle={footerTitle}
                  sitePath={sitePath}
                />
              )}
              <FanExpoHQFooterCalendar />
            </div>
          </div>
          <div className="col-xs-6">
            <div className="c-fan-expo-hq-footer__social-wrapper">
              <FanExpoHQFooterSocial socials={social} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

FanExpoHQFooter.propTypes = {
  siteFooter: PropTypes.object,
  siteHeader: PropTypes.object,
  bgColor: PropTypes.string,
  options: PropTypes.shape({
    data: PropTypes.shape({
      brand: PropTypes.shape({
        logo: PropTypes.shape({
          path: PropTypes.string,
        }),
        whiteLogo: PropTypes.shape({
          path: PropTypes.string,
        }),
      }),
    }),
  }),
  pageConfig: PropTypes.shape({
    sitePath: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    siteFooter: state.siteFooter,
    siteHeader: state.siteHeader,
    pageConfig: state.pageConfig,
    options: state.options,
  };
}

export default connect(mapStateToProps)(FanExpoHQFooter);
