import React from 'react';
import PropTypes from 'prop-types';

import { isAbsoluteUrl } from '../../../../../../helpers';

import FanExpoHQFooterTitle from '../FanExpoHQFooterTitle/FanExpoHQFooterTitle';
import CallToAction from '../../../../../shared/CallToAction/CallToAction';
import './FanExpoHQFooterPages.scss';

/**
 * WHITE LABEL
 */
export default function FanExpoHQFooterPages({
  footerLinks,
  footerTitle,
  sitePath,
}) {
  return (
    <div className="c-fan-expo-hq-footer-pages">
      <FanExpoHQFooterTitle title={footerTitle} />
      <ul className="c-fan-expo-hq-footer-pages__list">
        {footerLinks.map((page) => {
          return (
            <li
              key={page.text}
              className="c-fan-expo-hq-footer-pages__list-item"
            >
              <CallToAction
                type="link"
                to={page.url}
                sitePath={isAbsoluteUrl(page.url) ? null : sitePath}
                target={page.openInNewTab ? '_blank' : undefined}
                className="c-fan-expo-hq-footer-pages__link"
              >
                {page.text}
              </CallToAction>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

FanExpoHQFooterPages.propTypes = {
  footerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      text: PropTypes.string,
      openInNewTab: PropTypes.bool,
    }),
  ).isRequired,
  footerTitle: PropTypes.string,
  sitePath: PropTypes.string,
};
