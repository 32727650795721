import { connect } from 'react-redux';
import FanExpoHQFooterTitle from '../FanExpoHQFooterTitle/FanExpoHQFooterTitle';
import './FanExpoHQFooterCalendar.scss';

const calendar = {
  title: 'Event Calendar',
  list: [
    {
      name: '2024 Winter Show',
      date: 'Nov 21 to Dec 1 2024',
    },
    {
      name: '2025 Spring Show',
      date: 'Mar 26 to Mar 30 2025',
    },
  ],
};

export function FanExpoHQFooterCalendar(props) {
  const {
    pageConfig: { tenantId },
  } = props;

  if (tenantId !== 'ONEOFAKINDSHOW') return null;

  const { title, list = [] } = calendar;

  return (
    <div className="c-fan-expo-hq-footer-calendar">
      <FanExpoHQFooterTitle title={title} />
      {list.length > 0 && (
        <ul>
          {list.map((calendar) => {
            return (
              <li
                className="c-fan-expo-hq-footer-calendar__item"
                key={calendar.name}
              >
                <span className="c-fan-expo-hq-footer-calendar__item-name">
                  {calendar.name}
                </span>
                <span>{calendar.date}</span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(FanExpoHQFooterCalendar);
